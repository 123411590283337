import { useParams } from "react-router-dom";
import { SearchFilter } from "../../../services/movies/interfaces/SearchFilter";
import SearchPageTemplate from "../../templates/Search/SearchPageTemplate";
import MoviesGrid from "../../movie/MoviesGrid";
import PageSectionContainer from "../../others/PageSectionContainer";
import getText from "../../../services/lang/GetText";

const SearchSimilarMoviesPage = () => {
  const { lang, movieId } = useParams();
  const filter = { similar: movieId } as SearchFilter;
  return (
    <SearchPageTemplate>
      <PageSectionContainer
        headerTitle={{
          text: getText("searchBySimilarAnime", lang),
        }}
      >
        <MoviesGrid searchFilter={filter} />
      </PageSectionContainer>
    </SearchPageTemplate>
  );
};

export default SearchSimilarMoviesPage;
