import { useParams } from "react-router-dom";
import { SearchFilter } from "../../../services/movies/interfaces/SearchFilter";
import PageSectionContainer from "../../others/PageSectionContainer";
import ProfileHomePageTemplate from "../../templates/Profile/ProfileHomePageTemplate";
import { BoxArrowLeft, HeartFill } from "react-bootstrap-icons";
import getText from "../../../services/lang/GetText";
import MoviesGrid from "../../movie/MoviesGrid";

const Preferreds = () => {
  const { userId, lang, bot_id } = useParams();

  return (
    <ProfileHomePageTemplate active={2}>
      <PageSectionContainer
        headerTitle={{
          stickyHeader: true,
          text: getText("preferreds", lang),
          icon: HeartFill,
          redirectTo: "/" + lang + "/" + bot_id + "/profile/" + userId,
          iconRedirectTo: BoxArrowLeft,
        }}
      >
        <MoviesGrid
          searchFilter={
            {
              profile: { preferreds: true, userId, lang },
            } as SearchFilter
          }
        />
      </PageSectionContainer>
    </ProfileHomePageTemplate>
  );
};

export default Preferreds;
