import { Box, Stack, Heading, Image, Text, Icon } from "@chakra-ui/react";
import Episode from "../../services/movies/interfaces/Episode";
import DefaultConfig from "../../config/DefaultConfig";
import { PlayCircleFill } from "react-bootstrap-icons";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import openEpisode from "../../services/movies/utilities/OpenEpisode";
import { useParams } from "react-router-dom";

interface Props {
  episode: Episode;
  showPlay?: boolean;
}

const EpisodesCard = ({ episode, showPlay = true }: Props) => {
  const WebApp = useWebApp();

  const { bot_id } = useParams();

  const handleClickEpisode = (id: number, episodeNumber: number) => {
    openEpisode(
      bot_id,
      id,
      episodeNumber,
      () => WebApp.close(),
      WebApp.initDataUnsafe?.user?.id,
      WebApp.initData
    );
  };
  return (
    <>
      <Box maxW={"20%"}>
        <Image
          objectFit="cover"
          objectPosition="center"
          src={DefaultConfig.cloudPath + episode.movie.poster}
          style={{ transform: "scale(1.1)" }}
        />
      </Box>

      <Stack alignSelf="flex-start" mt={{ base: 2, md: 6 }} w={"70%"}>
        <Heading
          fontFamily={"Raleway"}
          fontSize={{ base: "13px", md: "20px", lg: "25px" }}
        >
          {episode.movie.name}{" "}
          {episode.movie.season > 0 ? "S" + episode.movie.season : ""}
        </Heading>
        <Text
          fontSize={{ base: "13px", md: "15px", lg: "20px" }}
          fontFamily={"Raleway"}
        >
          Episodio {episode.episodeNumber}
        </Text>
      </Stack>
      <Box
        w={"10%"}
        alignSelf={"flex-center"}
        onClick={() =>
          handleClickEpisode(episode.movie.id, episode.episodeNumber)
        }
      >
        {showPlay == true && (
          <Icon
            cursor={"pointer"}
            _active={{ color: "gray.400" }}
            boxSize={7}
            as={PlayCircleFill}
          ></Icon>
        )}
      </Box>
    </>
  );
};

export default EpisodesCard;
