
import apiClient from "../../API/MoviesServices/apiClient";
const saveNewComment = async (userId: number, movieId: number, episodeNumber: number, text: string, lang: string, webAppData: any) => {
    const data = {
        webapp_data: webAppData, comment: {
            userId,
            movieId,
            episodeNumber,
            text,
            lang
        }
    }
    return await apiClient.put('/episodes/comment/save', data);
}

export default saveNewComment;