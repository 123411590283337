import { Box, Text } from "@chakra-ui/react";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import UsersStats from "../../services/movies/interfaces/UsersStats";
import useData from "../../services/API/MoviesServices/hooks/useData";
import ProfileStatsCardContainer from "../profile/statistics/Basic/ProfileStatsCardContainer";
import ProfileStatsCardSkeleton from "../profile/statistics/Basic/ProfileStatsCardSkeleton";
import ProfileStatsCard from "../profile/statistics/Basic/ProfileStatsCard";
import { ClipboardDataFill } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";

interface Props {
  endpoint: string;
}

const UsersSlideStats = ({ endpoint }: Props) => {
  const { bot_id } = useParams();
  const params: Record<string, any> = {
    bot: bot_id,
  };
  const { isLoading, data } = useData<UsersStats>(endpoint, { params }, [
    bot_id,
  ]);
  return (
    <Box mb={5} w="100%" overflow="hidden">
      <Swiper
        slidesPerView="auto"
        spaceBetween={10}
        freeMode={true}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {isLoading &&
          Array.from({ length: 3 }, (_, index) => (
            <SwiperSlide style={{ width: "35%" }} key={index}>
              <ProfileStatsCardContainer>
                <ProfileStatsCardSkeleton />
              </ProfileStatsCardContainer>
            </SwiperSlide>
          ))}

        {!isLoading && data && data.total !== undefined && (
          <>
            <SwiperSlide style={{ width: "35%" }}>
              <ProfileStatsCardContainer>
                <ProfileStatsCard icon={ClipboardDataFill} title="TOTAL">
                  {data?.total}
                </ProfileStatsCard>
              </ProfileStatsCardContainer>
            </SwiperSlide>
            {data.langs?.map((e, index) => (
              <SwiperSlide style={{ width: "35%" }} key={index}>
                <ProfileStatsCardContainer>
                  <ProfileStatsCard
                    icon={ClipboardDataFill}
                    title={`TOTAL ${e.lang.toUpperCase()}`}
                  >
                    <Text>{e.tot}</Text>
                  </ProfileStatsCard>
                </ProfileStatsCardContainer>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Box>
  );
};

export default UsersSlideStats;
