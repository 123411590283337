import { SimpleGrid } from "@chakra-ui/react";
import DefaultConfig from "../../config/DefaultConfig";
import { useState } from "react";
import usePageScroll from "../../services/movies/hooks/usePageScroll";
import { Movie } from "../../services/movies/interfaces/Movie";
import { SearchFilter } from "../../services/movies/interfaces/SearchFilter";
import MovieCard from "./MovieCard";
import MovieCardContainer from "./MovieCardContainer";
import MovieCardSkeleton from "./MovieCardSkeleton";
import useMovies from "../../services/movies/hooks/useMovies";

interface Props {
  searchFilter: SearchFilter;
  pagiantionOnScroll?: boolean;
}

const MoviesGrid = ({ searchFilter, pagiantionOnScroll = true }: Props) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, error, isEmptyData } = useMovies<Movie>(
    searchFilter,
    page
  );
  usePageScroll(
    !isLoading &&
      !isEmptyData &&
      pagiantionOnScroll &&
      (searchFilter.maxPages ? searchFilter.maxPages > page : true),
    () => {
      setPage(page + 1);
    },
    [isLoading, isEmptyData]
  );
  if (error) return null;

  return (
    <SimpleGrid spacing={2} columns={{ base: 3, md: 4, lg: 5 }} mb={4}>
      {data.map((movie) => (
        <MovieCardContainer key={movie.id}>
          <MovieCard movie={movie} />
        </MovieCardContainer>
      ))}
      {isLoading &&
        Array.from(
          { length: DefaultConfig.searchItemsLimitPerPage },
          (_, index) => (
            <MovieCardContainer key={index}>
              <MovieCardSkeleton key={index} />
            </MovieCardContainer>
          )
        )}
    </SimpleGrid>
  );
};

export default MoviesGrid;
