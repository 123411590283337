import { HStack, Icon, Text } from "@chakra-ui/react";
import { PlayCircleFill } from "react-bootstrap-icons";
import StreamingData from "../../../services/movies/interfaces/StreamingData";

interface Props {
  stream: StreamingData;
}

const StreamingOptionCard = ({ stream }: Props) => {
  return (
    <HStack>
      <Icon
        _active={{ color: "gray.400" }}
        boxSize={4}
        as={PlayCircleFill}
      ></Icon>
      <Text>{stream.resolution}</Text>
    </HStack>
  );
};

export default StreamingOptionCard;
