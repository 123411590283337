import apiClient from "../../API/Bot/apiClient";
const openMovie = async (bot_id: any, id: number, callback: () => void, userId?: number, data?: any) => {
    const endpoint = `?bot=${bot_id}&webapp=1&callback_data=Movie:view|${id}|-1&to_user=${userId}&${data}`;
    return apiClient.get(endpoint).then(async () => {
        callback()
    }).catch(async () => {
        callback()
    });
}

export default openMovie;