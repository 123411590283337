import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import useDataMany from "../../services/API/MoviesServices/hooks/useDataMany";
import { Box, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

interface SubscriptionData {
  name: string;
  users: number;
}

const WeeklySubscriptionsChart = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { bot_id } = useParams();
  const params: Record<string, any> = {
    bot: bot_id,
  };
  const { data, isLoading } = useDataMany<SubscriptionData>(
    "stats/users/subscriptions",
    { params },
    [bot_id],
    false
  );

  const chartHeight = isLargerThan768 ? 400 : 250;

  return (
    <Box>
      {isLoading ? (
        <Skeleton width="100%" height={chartHeight} />
      ) : (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 20, left: 0, bottom: 5 }}
          >
            <XAxis dataKey="name" interval={0} angle={-45} />
            <YAxis
              domain={[
                0,
                data && data.length > 0
                  ? Math.max(...data.map((entry) => entry.users)) + 50
                  : 100,
              ]}
              tickCount={10}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="users"
              stroke="#8884d8"
              strokeWidth={3}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default WeeklySubscriptionsChart;
