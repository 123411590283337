import { Box, Skeleton } from "@chakra-ui/react";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import StreamingData from "../../../services/movies/interfaces/StreamingData";
import StreamingOptionCardContainer from "./StreamingOptionCardContainer";
import StreamingOptionCard from "./StreamingOptionCard";

interface Props {
  isLoading: boolean;
  streams: StreamingData[];
  activeStreamId: number;
  handleClick: (stream: number) => void;
}
const StreamingOptionsSlide = ({
  isLoading,
  streams,
  activeStreamId,
  handleClick,
}: Props) => {
  return (
    <Box w="100%" overflow="hidden">
      <Swiper
        slidesPerView="auto"
        spaceBetween={10}
        freeMode={true}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        {isLoading &&
          Array.from({ length: 3 }, (_, index) => (
            <SwiperSlide style={{ width: "25%" }} key={index}>
              <StreamingOptionCardContainer>
                <Skeleton w={"100%"} h={"100%"} />
              </StreamingOptionCardContainer>
            </SwiperSlide>
          ))}

        {!isLoading &&
          streams.map((stream, i) => (
            <SwiperSlide
              style={{ width: "25%" }}
              key={i}
              onClick={() => handleClick(i)}
            >
              <StreamingOptionCardContainer isActive={activeStreamId == i}>
                <StreamingOptionCard stream={stream} />
              </StreamingOptionCardContainer>
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  );
};

export default StreamingOptionsSlide;
