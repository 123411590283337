import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SearchByNamePage from "./components/pages/searchs/SearchByNamePage";
import RecapSeriesPage from "./components/pages/profile/RecapSeriesPage";
import ProfileHome from "./components/pages/profile/ProfileHome";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import WatchLists from "./components/pages/profile/WatchLists";
import Preferreds from "./components/pages/profile/Preferreds";
import HomePage from "./components/pages/HomePage";
import SearchByLetterIndexPage from "./components/pages/searchs/SearchByLetterIndexPage";
import SearchByCategoryPage from "./components/pages/searchs/SearchByCategoryPage";
import SearchByYearPage from "./components/pages/searchs/SearchByYearPage";
import SearchByEpisodesPage from "./components/pages/searchs/SearchByEpisodesRangePage";
import CalendarPage from "./components/pages/calendar/Calendar";
import StatisticsPage from "./components/pages/statistics/StatisticsPage";
import MovieEpisodeComments from "./components/pages/episodes/comments/MovieEpisodeComments";
import MoviesHistory from "./components/pages/profile/MoviesHistory";
import SearchSimilarMoviesPage from "./components/pages/searchs/SearchSimilarMoviesPage";
import TopMovies from "./components/pages/top/TopMovies";
import SearchByRelatedMoviesPage from "./components/pages/searchs/SearchRelatedAnimePage";
import MovieEpisodeStreamingPage from "./components/pages/episodes/streaming/MovieEpisodeStreamingPage";

function App() {
  const WebApp = useWebApp();
  WebApp.expand();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:lang/:bot_id" element={<HomePage />} />
        <Route
          path="/:lang/:bot_id/search/:search"
          element={<SearchByNamePage />}
        />
        <Route
          path="/:lang/:bot_id/search/azlist/:index"
          element={<SearchByLetterIndexPage />}
        />
        <Route
          path="/:lang/:bot_id/search/category/:category"
          element={<SearchByCategoryPage />}
        />
        <Route
          path="/:lang/:bot_id/search/year/:year"
          element={<SearchByYearPage />}
        />
        <Route
          path="/:lang/:bot_id/search/episodes-range/:epMin/:epMax"
          element={<SearchByEpisodesPage />}
        />

        <Route
          path="/:lang/:bot_id/search/related/:movieId"
          element={<SearchByRelatedMoviesPage />}
        />

        <Route
          path="/:lang/:bot_id/search/similar/:movieId"
          element={<SearchSimilarMoviesPage />}
        />

        <Route
          path="/:lang/:bot_id/profile/:userId/recap-series"
          element={<RecapSeriesPage />}
        />
        <Route
          path="/:lang/:bot_id/profile/:userId"
          element={<ProfileHome />}
        />
        <Route
          path="/:lang/:bot_id/profile/:userId/movies/history"
          element={<MoviesHistory />}
        />

        <Route
          path="/:lang/:bot_id/profile/:userId/movies/watchlist"
          element={<WatchLists />}
        />

        <Route
          path="/:lang/:bot_id/profile/:userId/movies/preferreds"
          element={<Preferreds />}
        />

        <Route path="/:lang/:bot_id/movies/top" element={<TopMovies />} />

        <Route
          path="/:lang/:bot_id/movies/:movieId/episodes/:episodeNumber/comments"
          element={<MovieEpisodeComments />}
        />

        <Route
          path="/:lang/:bot_id/movie/:movieId/episode/:episodeNumber/streaming"
          element={<MovieEpisodeStreamingPage />}
        />

        <Route path="/:lang/:bot_id/movies/airing" element={<CalendarPage />} />

        <Route path="/:bot_id/admin/stats" element={<StatisticsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
