import { useParams } from "react-router-dom";
import { Box, Stack } from "@chakra-ui/react";
import useData from "../../../../services/API/MoviesServices/hooks/useData";
import EpisodeCommentsTemplate from "../../../templates/comments/EpisodeCommentsTemplate";
import PageSectionContainer from "../../../others/PageSectionContainer";
import EpisodesCardContainer from "../../../episodes/EpisodesCardContainer";
import EpisodesCard from "../../../episodes/EpisodesCard";
import EpisodeCardSkeleton from "../../../episodes/EpisodesSkeleton";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { Badge4kFill } from "react-bootstrap-icons";
import { Movie } from "../../../../services/movies/interfaces/Movie";
import StreamingData from "../../../../services/movies/interfaces/StreamingData";
import VideoPlayer from "../../../others/VideoPlayer";
import { useState } from "react";
import useDataManyPost from "../../../../services/API/MoviesServices/hooks/useDataManyPost";
import StreamingOptionsSlide from "../../../episodes/Streaming/StreamingOptionsSlide";

function MovieEpisodeStreamingPage() {
  const { lang, movieId, episodeNumber } = useParams();
  const { data: movieData, isLoading: movieLoading } = useData<Movie>(
    "/movies/info/" + movieId,
    {
      params: { lang: lang },
    }
  );

  const [stream, setStream] = useState(0);

  const webApp = useWebApp();

  const { data: streamingData, isLoading: streamingLoad } =
    useDataManyPost<StreamingData>(
      `/episodes/${movieId}/streams`,
      { webapp_data: webApp.initDataUnsafe },
      {
        params: { episode: episodeNumber },
      },
      [],
      false
    );

  return (
    <EpisodeCommentsTemplate>
      <Stack pt={3} position="relative" spacing={3}>
        <Box
          height={{ base: "10%", md: "20%", lg: "30%" }}
          position="sticky"
          top={0}
          zIndex={100}
          bg={"gray.800"}
        >
          <PageSectionContainer
            headerTitle={{ text: "STREAMING", icon: Badge4kFill }}
          >
            {!streamingLoad && streamingData && (
              <VideoPlayer source={streamingData[stream]?.url} />
            )}

            {
              <StreamingOptionsSlide
                streams={streamingData}
                isLoading={streamingLoad}
                activeStreamId={stream}
                handleClick={setStream}
              />
            }
            {!movieLoading && movieData && (
              <EpisodesCardContainer>
                <EpisodesCard
                  showPlay={false}
                  episode={{
                    movie: movieData,
                    episodeNumber: parseInt(episodeNumber ?? ""),
                  }}
                />
              </EpisodesCardContainer>
            )}

            {movieLoading && (
              <EpisodesCardContainer>
                <EpisodeCardSkeleton />
              </EpisodesCardContainer>
            )}
          </PageSectionContainer>
        </Box>
      </Stack>
    </EpisodeCommentsTemplate>
  );
}

export default MovieEpisodeStreamingPage;
