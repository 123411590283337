import DefaultConfig from "../../../config/DefaultConfig";
import { SearchFilter } from "../interfaces/SearchFilter";
import useDataMany from "../../API/MoviesServices/hooks/useDataMany";

const useMovies = <T>({
    name,
    ongoing,
    lastAired,
    lastUpdated,
    getWatchLists,
    ranking,
    lang,
    profile,
    index,
    category,
    year,
    episodesRange,
    related,
    similar
}: SearchFilter, page: number = 1, limit: number = DefaultConfig.searchItemsLimitPerPage) => {

    const params: Record<string, any> = {
        page,
        limit,
        lang,
    };

    let endpoint = '/movies/name/all';

    if (name) {
        endpoint = "/movies/name";
        params['keyword'] = name;
    } else if (profile) {
        endpoint = `/users/profile/${profile.userId}`;
        if (profile.recap) {
            endpoint += `/unwatchedEpisodes/${profile.recap}`;
        } else if (profile.history) {
            endpoint += "/movies/history";
        } else if (profile.preferreds) {
            endpoint += "/movies/preferreds";
        } else if (profile.recentViewed) {
            endpoint += "/movies/lastWatched";
        } else if (profile.watchlist) {
            endpoint += `/movies/watchlist/${profile.watchlist}`;
        }
    } else if (getWatchLists) {
        endpoint = "/movies/watchlists";
    } else if (ranking) {
        endpoint = `/movies/ranking/${ranking}`;
    } else if (ongoing) {
        endpoint = `movies/ongoing/${ongoing}`;
    } else if (lastAired) {
        endpoint = `movies/lasts/uploaded`;
    }
    else if (lastUpdated) {
        endpoint = `movies/lasts/updated`;
    } else if (index) {
        endpoint = `movies/azlist/${index}`;
    }
    else if (category) {
        endpoint = `movies/category/${category}`;
    }
    else if (year) {
        endpoint = `movies/year/${year}`;
    }
    else if (episodesRange) {
        endpoint = `movies/filter/episodes`;
        params['min'] = episodesRange.min;
        params['max'] = episodesRange.max;
    }
    else if (related) {
        endpoint = `movies/related/${related}`;
    }
    else if (similar) {
        endpoint = `movies/similar/${similar}`;
    }

    return useDataMany<T>(endpoint, { params }, [page]);
};

export default useMovies;