const AppText: any = {
    searchByName: {
        it: 'RISULTATI PER "{keyword}"',
        en: 'RESULTS FOR "{keyword}"',
        fr: 'RÉSULTATS POUR "{keyword}"',
    },
    searchByStudio: {
        it: '{keyword}',
        en: '{keyword}',
        fr: '{keyword}',
    },
    searchByIndex: {
        it: 'INDICE "{keyword}"',
        en: 'INDEX "{keyword}"',
        fr: 'INDICE "{keyword}"',
    },
    searchByCategory: {
        it: 'CATEGORIA "{keyword}"',
        en: 'CATEGORY "{keyword}"',
        fr: 'Catégorie "{keyword}"',
    },
    searchByYear: {
        it: 'MOVIES USCITI NEL "{keyword}"',
        en: 'MOVIES RELEASED IN "{keyword}"',
        fr: 'ANIMÉS SORTIS EN "{keyword}"',
    },
    searchByEpisodes: {
        it: 'RISULTATI PER "{keyword}" episodi',
        en: 'RESULTS FOR "{keyword}" episodes',
        fr: 'RÉSULTATS POUR "{keyword}" Épisodes',
    },

    searchByRelatedAnime: {
        it: 'MOVIES CORRELATI',
        en: 'RELATED ANIME',
        fr: 'ANIMÉS CORRÉLÉS',
    },
    searchBySimilarAnime: {
        it: 'MOVIES SIMILI CHE POTREBBERO PIACERTI',
        en: "Similar MOVIES You Might Like",
        fr: "MOVIES Similaires Que Vous Pourriez Aimer",
    },
    stats: {
        it: "Statistiche",
        en: "Statistics",
        fr: "Statistiques",
    },
    animetime: {
        it: "Tempo visione",
        en: "Movies time",
        fr: "Temps d'anime",
    },
    episodesWatched: {
        it: "Episodi visti",
        en: "Episodes watched",
        fr: "Épisodes vus",
    },
    animeWatched: {
        it: "Serie visti",
        en: "Series watched",
        fr: "Series vus",
    },
    animeHistory: {
        it: "Cronologia movies",
        en: "Movies history",
        fr: "Historique movies",
    },
    filmWatched: {
        it: "Film visti",
        en: "Films watched",
        fr: "Films vus",
    },
    comments: {
        it: "Commenti",
        en: "Comments",
        fr: "Commentaires",
    },
    watchlist: {
        it: "LISTA MOVIES",
        en: "MOVIES LIST",
        fr: "LISTE D'ANIMES",
    },
    preferreds: {
        it: "PREFERITI",
        en: "FAVORITES",
        fr: "PRÉFÉRÉS",
    },
    watchTheNext: {
        it: "GUARDA IL PROSSIMO",
        en: "WATCH THE NEXT",
        fr: "REGARDER LE PROCHAIN",
    },
    notWatchedFromAWhile: {
        it: "NON VEDI DA UN PO'",
        en: "NOT WATCHED FROM A WHILE",
        fr: "PAS REGARDÉ DEPUIS UN MOMENT",
    },
    mostVoted: {
        it: "PIU VOTATI",
        en: "MOST VOTED",
        fr: "LES PLUS VOTÉS",
    },
    mostViewed: {
        it: "PIU VISTI",
        en: "MOST VIEWED",
        fr: "LES PLUS VUS",
    },
    mostLoved: {
        it: "PIU AMATI",
        en: "MOST LOVED",
        fr: "LES PLUS AIMÉS",
    },
    calendar: {
        it: "CALENDARIO",
        en: "CALENDAR",
        fr: "CALENDRIER",
    },
    weekDays: {
        it: [
            { day_name: "Lunedì", id_day: 0 },
            { day_name: "Martedì", id_day: 1 },
            { day_name: "Mercoledì", id_day: 2 },
            { day_name: "Giovedì", id_day: 3 },
            { day_name: "Venerdì", id_day: 4 },
            { day_name: "Sabato", id_day: 5 },
            { day_name: "Domenica", id_day: 6 },
        ],
        en: [
            { day_name: "Monday", id_day: 0 },
            { day_name: "Tuesday", id_day: 1 },
            { day_name: "Wednesday", id_day: 2 },
            { day_name: "Thursday", id_day: 3 },
            { day_name: "Friday", id_day: 4 },
            { day_name: "Saturday", id_day: 5 },
            { day_name: "Sunday", id_day: 6 },
        ],
        fr: [
            { day_name: "Lundi", id_day: 0 },
            { day_name: "Mardi", id_day: 1 },
            { day_name: "Mercredi", id_day: 2 },
            { day_name: "Jeudi", id_day: 3 },
            { day_name: "Vendredi", id_day: 4 },
            { day_name: "Samedi", id_day: 5 },
            { day_name: "Dimanche", id_day: 6 },
        ],
    },
    monthNames: {
        it: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        fr: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    },
    episode: {
        it: "Episodio",
        en: "Episode",
        fr: "Épisode"
    },
    calendarEpisode: {
        it: { out: "uscito alle", notOut: "uscirà alle" },
        en: { out: "aired at", notOut: "airing at" },
        fr: { out: "libéré à", notOut: "sortira à 12h" }
    },
    hours: {
        it: 'ore',
        en: 'hours',
        fr: 'heures'
    },
    days: {
        it: 'giorni',
        en: 'days',
        fr: 'jours'
    },
    months: {
        it: 'mesi',
        en: 'months',
        fr: 'mois'
    },
    lastAnimeAired: {
        it: 'ultimi movies usciti',
        en: 'last aired movies',
        fr: 'derniers animes diffusés'
    },
    lastAnimeEpisodes: {
        it: 'ultimi episodi usciti',
        en: 'last aired episodes',
        fr: 'derniers épisodes diffusés'
    },
    areYouSure: {
        it: "Sei sicuro?",
        en: "Are you sure?",
        fr: "Êtes-vous sûr ?"
    },
    yesDelete: {
        it: "Si, elimina!",
        en: "Yes, delete it!",
        fr: "Oui, supprimez-le !"
    },
    cancel: {
        it: "Annulla",
        en: "Cancel",
        fr: "Annuler"
    },
    deleted: {
        it: "Eliminato",
        en: "Deleted",
        fr: "Supprimé"
    },
    commentDeleted: {
        it: "Il commento è stato eliminato!",
        en: "Your comment has been deleted.",
        fr: "Votre commentaire a été supprimé."
    },
    noCommentsFound: {
        it: "Non ci sono commenti, approfitta per lasciarne uno per primo!",
        en: "There are no comments yet, take the opportunity to leave the first one!",
        fr: "Il n'y a pas encore de commentaires, profitez-en pour laisser le premier !"
    }
};

export default AppText;
