import { Box, Image, Text } from "@chakra-ui/react";
import DefaultConfig from "../../config/DefaultConfig";
import CardShadow from "../others/CardShadow";
import { useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import openMovie from "../../services/movies/utilities/OpenMovie";
import { useState } from "react";
import { EyeFill, HeartFill, StarFill } from "react-bootstrap-icons";
import MoviePlayEffect from "./MoviePlayEffect";
import MovieCardBadgeTopRight from "./badges/MovieCardBadgeTopRight";
import openEpisode from "../../services/movies/utilities/OpenEpisode";
import MovieCardBadgeTopLeft from "./badges/MovieCardBadgeTopLeft";
import { Movie } from "../../services/movies/interfaces/Movie";
import { useParams } from "react-router-dom";

interface Props {
  movie: Movie;
}

const MovieCard = ({ movie }: Props) => {
  const { bot_id } = useParams();
  const WebApp = useWebApp();
  const [initDataUnsafe, initData] = useInitData();

  const [clicked, setClicked] = useState(false);

  if (clicked) {
    if (movie?.episodeNumber) {
      openEpisode(
        bot_id,
        movie.id,
        movie.episodeNumber,
        () => WebApp.close(),
        initDataUnsafe?.user?.id,
        initData
      );
    } else {
      openMovie(
        bot_id,
        movie.id,
        () => WebApp.close(),
        initDataUnsafe?.user?.id,
        initData
      );
    }
  }

  return (
    <Box onClick={() => setClicked(true)} width={"100%"} height={"100%"}>
      <Image
        src={DefaultConfig.cloudPath + movie.poster}
        width="100%"
        height="100%"
        objectFit="cover"
        objectPosition="center"
        style={{ transform: "scale(1.1)" }}
      />
      <CardShadow />
      <Box
        position="absolute"
        bottom="0"
        left="0"
        padding={{ base: 2, md: 2, lg: 2 }}
      >
        <Text
          fontFamily={"Raleway"}
          fontWeight={"bold"}
          color="white"
          fontSize={{ base: "12px", md: "15px", lg: "17px" }}
          lineHeight={1.1}
          align={"left"}
        >
          {movie.name} {movie.season > 0 ? "S" + movie.season : ""}
        </Text>
      </Box>
      {movie?.average && (
        <MovieCardBadgeTopRight
          text={movie.average}
          icon={{ source: StarFill, color: "yellow.400" }}
        />
      )}

      {movie?.viewCount && (
        <MovieCardBadgeTopRight
          text={movie.viewCount}
          icon={{ source: EyeFill, color: "white.400" }}
        />
      )}

      {movie?.preferredsCount && (
        <MovieCardBadgeTopRight
          text={movie.preferredsCount}
          icon={{ source: HeartFill, color: "red.400" }}
        />
      )}

      {movie?.episodeNumber && (
        <MovieCardBadgeTopRight text={"EP" + movie.episodeNumber} />
      )}

      {movie?.category &&
        ((movie.category !== "TVSERIES" && (
          <MovieCardBadgeTopLeft
            text={movie.category.toUpperCase()}
            color={"red.500"}
          />
        )) ||
          (movie.category == "TVSERIES" && (
            <MovieCardBadgeTopLeft text={" TV "} color={"green.500"} />
          )))}

      {clicked && <MoviePlayEffect />}
    </Box>
  );
};

export default MovieCard;
