import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Box } from "@chakra-ui/react";
import DefaultConfig from "../../../config/DefaultConfig";
import { Movie } from "../../../services/movies/interfaces/Movie";
import { SearchFilter } from "../../../services/movies/interfaces/SearchFilter";
import MovieCard from "../MovieCard";
import MovieCardContainerSlide from "../MovieCardContainerSlide";
import MovieCardSkeleton from "../MovieCardSkeleton";
import useMovies from "../../../services/movies/hooks/useMovies";

interface Props {
  filter: SearchFilter;
}

const MovieSlidesPreview = ({ filter }: Props) => {
  const { data, isLoading } = useMovies<Movie>(filter, 1, 8);
  return (
    <Box mb={5} w={"100%"} overflow={"hidden"}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={10}
        freeMode={true}
        modules={[FreeMode]}
        className="animeSlides"
      >
        {data.map((a) => (
          <SwiperSlide style={{ width: "35%" }} key={a.id}>
            <MovieCardContainerSlide>
              <MovieCard movie={a} />
            </MovieCardContainerSlide>
          </SwiperSlide>
        ))}
        {isLoading &&
          Array.from(
            {
              length: DefaultConfig.searchItemsLimitPerPage,
            },
            (_, index) => (
              <SwiperSlide style={{ width: "32%" }} key={index}>
                <MovieCardContainerSlide>
                  <MovieCardSkeleton key={index} />
                </MovieCardContainerSlide>
              </SwiperSlide>
            )
          )}
      </Swiper>
    </Box>
  );
};

export default MovieSlidesPreview;
