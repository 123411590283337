import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";
interface Props {
  children: ReactNode;
  isActive?: boolean;
}
const StreamingOptionCardContainer = ({
  children,
  isActive = false,
}: Props) => {
  return (
    <Box
      cursor={"pointer"}
      height={{ base: "30px", md: "50px", lg: "50px" }}
      bg={isActive ? "gray.700" : "gray.900"}
      borderRadius={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Box>
  );
};

export default StreamingOptionCardContainer;
