import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-overlay";
import "./VideoPlayer.css";
import VideoBanner from "../../assets/video/video_banner_.png";
import { Box } from "@chakra-ui/react";

interface Props {
  source: string;
}

const VideoPlayer = ({ source }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (!videoRef.current) return; // Ensure the ref is defined
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime();
      const updatedSource = `${source}?t=${new Date().getTime()}`;
      playerRef.current.src({ src: updatedSource, type: "video/mp4" });
      playerRef.current.currentTime(currentTime);
      return;
    }

    console.log(source);
    playerRef.current = videojs(
      videoRef.current,
      {
        fluid: true,
        responsive: true,
        sources: [
          {
            src: source,
            type: "video/mp4",
          },
        ],
      },
      () => {
        videojs.log("Player is ready");
      }
    );

    // Add overlay to the player
    if (playerRef.current) {
      playerRef.current.overlay({
        content: `<img src="${VideoBanner}" alt="Sticker">`,
        overlays: [
          {
            start: "playing",
            end: 25,
            align: "bottom",
            className: "vjs-overlay",
          },
        ],
      });
    }

    // Handle time updates to show/hide the overlay
    playerRef.current?.on("timeupdate", () => {
      const overlays = playerRef
        .current!.el()
        .getElementsByClassName("vjs-overlay");
      for (let i = 0; i < overlays.length; i++) {
        if (playerRef.current!.currentTime() > 25) {
          overlays[i].style.display = "none";
        } else {
          overlays[i].style.display = "block";
        }
      }
    });

    // Clean up the player on component unmount
    return () => {};
  }, [source]);

  return (
    <Box w={"100%"} h={"auto"}>
      <video ref={videoRef} className="video-js " controls preload="auto" />
    </Box>
  );
};

export default VideoPlayer;
